<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.group_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-form ref="mainForm">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
                </v-toolbar>             
                <v-card class="mt-5">                
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.admin_group_id" disabled label="Rec Id" outlined></v-text-field>                                                                
                                <v-text-field v-model="form.group_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.group_description" label="Description" :rules="inputRules" outlined></v-text-field>
                                <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.start_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.end_date"
                                            label="End Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                                </v-menu>                                
                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                                <v-select
                                    v-model="form.list_merchant_id"
                                    :items="merchantList"
                                    item-text="merchant_name"
                                    item-value="merchant_id"
                                    label="Merchant"
                                    multiple
                                    chips
                                    persistent-hint
                                    outlined
                                    >
                                </v-select>                                
                                <v-select
                                    v-model="form.list_admin_user_id"
                                    :items="userList"
                                    item-text="full_name"
                                    item-value="admin_user_id"
                                    label="Users"
                                    multiple
                                    chips
                                    persistent-hint
                                    outlined
                                    >
                                </v-select>
                                <v-btn dark color="cbtnSave" @click="save">save</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                
                    <v-toolbar dark color="panel2" class="mt-5">
                        <v-toolbar-title>Access List</v-toolbar-title>
                    </v-toolbar>                
                <v-card class="mt-5">                
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                    <v-list flat subheader  v-for="accessCategory in accessCategoryList" :key="accessCategory.category">
                                        <v-subheader dark class="panel3">
                                            <v-checkbox :key="accessCategory.category" @click="selectByCategory(accessCategory.category)" :value="accessCategory.category" v-model="tickCategory[accessCategory.category]"></v-checkbox>
                                            {{accessCategory.category}}
                                        </v-subheader>

                                        <v-list-item v-for="access in accessFiltered(accessCategory.category)" :key="access.code">
                                            <v-list-item-action>
                                                    <v-checkbox v-model="form.list_admin_access_id" :value="access.admin_access_id"></v-checkbox>
                                            </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>{{access.name}}</v-list-item-subtitle>
                                                </v-list-item-content>

                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-btn dark color="cbtnSave" @click="save">save</v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-container>                            
                    </v-card>
                </v-form>                                
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApUserService from "@/services/ApUserService"
import ApParameterService from "@/services/ApParameterService"
import moment from 'moment'
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Users',disabled: false,href:'/users/',},
              {text:'Groups',disabled: false,href:'/users/groups',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            merchantList:[],
            userList:[],
            accessList:[],
            accessCategoryList:[],
            routeParam:[],
            tickCategory:[],
            form:{
                login_id:null,
                admin_group_id:0,
                group_name:"",
                },
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  computed: {
  },
  async created() {
    this.routeParam['admin_group_id'] = this.$route.params.admin_group_id;
    //this.routeParam['group_name'] = atob(this.$route.params.enc_group_name);
    //this.form.group_name = atob(this.$route.params.enc_group_name);
    this.getParameter();
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        
        this.form.admin_group_id = this.routeParam.admin_group_id
        try{
            ApUserService.view_user_group(this.form).then((res) => {
                this.form = res.data.data;
                //console.log(this.form);
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                if(this.form.start_date == "0000-00-00" || !this.form.start_date)
                    this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
                
                if(this.form.end_date == "0000-00-00" || !this.form.end_date)
                    this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

                // //console.log(res.data);
                this.status_list = res.data.statusList; 
                this.setAccessTickAll();
               
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View User Group',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async getParameter(){
            try{
                let parameter = {login_id:"",param_type:"add-user_group"}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.accessCategoryList = res.data.accessCategoryList;
                    this.accessList = res.data.accessList;
                    this.userList = res.data.userList;
                    this.merchantList = res.data.merchantList;
                    //console.log(res.data);
                    
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        },    
    async save(){
        try{
            ApUserService.update_user_group(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update User Group','Success.');
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update User Group',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    
    accessFiltered(category){
          return this.accessList.filter(item => item.category === category)
    },
    setAccessTickAll(){
        
        for(let indexCategory in this.accessCategoryList){
            let filterAccess = this.accessFiltered(this.accessCategoryList[indexCategory]['category']);
            let lengthByCategory = filterAccess.length;
            let find = 0;
            //console.log(filterAccess);
            for(let indexFilterAccess in filterAccess){
                
                let admin_access_id = filterAccess[indexFilterAccess]['admin_access_id'];
                let indexInList = this.form.list_admin_access_id.indexOf(admin_access_id);
                if(indexInList > -1){
                    find++;
                }
            }
            if(lengthByCategory == find){
                this.tickCategory[this.accessCategoryList[indexCategory]['category']] = this.accessCategoryList[indexCategory]['category'];
            }
            
        }
        
    },

    selectByCategory(category){
        let filterAccess = this.accessFiltered(category);
        let accessTmp;
        //console.log(category);
        //console.log('before',this.form.list_admin_access_id);
        if(this.tickCategory[category]){
            this.removeFromList(filterAccess);
            for(accessTmp in filterAccess){
                this.form.list_admin_access_id.push(filterAccess[accessTmp]['admin_access_id']);
                //console.log(filterAccess[accessTmp]['admin_access_id']);
            }            
        }else{
            this.removeFromList(filterAccess);
        }
        //console.log('after',this.form.list_admin_access_id);
    },
    removeFromList(filterAccess){
        let accessTmp,accessIndex;
        for(accessTmp in filterAccess){
            accessIndex = this.form.list_admin_access_id.indexOf(filterAccess[accessTmp]['admin_access_id']);
            //console.log('accessIndex',accessIndex);
            if(accessIndex != -1){
                this.form.list_admin_access_id.splice(accessIndex,1);                   
            }
        }            
    },

  }
}
</script>